import PropTypes from "prop-types";
import React from "react";
import { Tween } from "react-gsap";
import { Controller, Scene } from "react-scrollmagic";
import Slider from "react-slick";
import slugify from "slugify";

import EditorialTitle from "@/components/EditorialTitle";
import VideoPlayer from "@/components/VideoPlayer";
import EditorialCard2Column from "@/components/cards/editorial-card-2-column";
import { getStyleObject } from "@/utils/helpers/module_style_tags";
import withScreenDimensions from "@/utils/helpers/screen_dimensions";

import styles from "./index.module.scss";

const EditorialGrid2ColumnModule = ({
  data,
  isDesktop,
  isMobile,
  propStyles,
}) => {
  const { cards, editorialTitle, marginBottom, subNavLabel, anchorTag } = data;

  const oddCards = cards.filter((_, index) => index % 2 === 1);
  const evenCards = cards.filter((_, index) => index % 2 === 0);
  const sectionSlug = slugify(subNavLabel || "").toLowerCase();
  const desktopMarginBottom = isDesktop ? getStyleObject({ marginBottom }) : {};
  const moduleStyles = {
    ...propStyles,
    ...desktopMarginBottom,
  };
  const scrollSlug = slugify(subNavLabel || "").toLowerCase();

  return (
    <section
      className={`container ${styles.moduleEditorialGrid2Column} module`}
      data-attr-module-name="module-editorial-grid-two-column"
      data-attr-scroll={anchorTag || scrollSlug}
      id={sectionSlug}
      style={moduleStyles}
    >
      <div className="row row-eq-height">
        <div
          className={`col-lg-4 col-md-9 col-sm-16 no-gutters ${styles.moduleEditorialGrid2ColumnTitle}`}
        >
          <div className={`${styles.moduleEditorialGrid2ColumnTitleWrap}`}>
            <EditorialTitle data={editorialTitle} />
          </div>
        </div>
        {isDesktop ? (
          <Controller>
            {[evenCards, oddCards].map((cards, colIndex) => {
              const cardCount = cards.length;

              return (
                <Scene
                  duration={`${cardCount * 125}%`}
                  key={["even", "odd"][colIndex]}
                  triggerElement={`#${sectionSlug}`}
                >
                  {(progress) => {
                    return (
                      <Tween
                        from={{ css: { top: `${colIndex * 180}px` } }}
                        paused
                        to={{ css: { top: `${-colIndex * 100}px` } }}
                        totalProgress={progress}
                      >
                        <div
                          className="col-lg-5 offset-lg-1"
                          style={{ position: "relative" }}
                        >
                          {cards.map((card) => {
                            const {
                              sys: { id },
                              fields: {
                                media: { fields: mediaFields },
                                title,
                                subtitle,
                                subcopy,
                                tag,
                                tertiaryCopy,
                                urlRef,
                                cta,
                                eyebrow,
                              },
                            } = card;

                            if (mediaFields.imageTitle) {
                              return (
                                <EditorialCard2Column
                                  colIndex={0}
                                  cta={cta}
                                  eyebrow={eyebrow}
                                  id={id}
                                  key={id}
                                  mediaFields={mediaFields}
                                  subcopy={subcopy}
                                  subtitle={subtitle}
                                  tag={tag}
                                  tertiaryCopy={tertiaryCopy}
                                  title={title}
                                  urlRef={urlRef}
                                />
                              );
                            } else if (mediaFields.videoTitle) {
                              const videoAsset = mediaFields.videoFile
                                ? mediaFields.videoFile.fields.file
                                : null;
                              const posterSrc = mediaFields.coverImageFile
                                ? `https:${mediaFields.coverImageFile.fields.file.url}`
                                : null;

                              return (
                                <div
                                  className={styles.editorialSingleColumnCard}
                                  key={id}
                                >
                                  <VideoPlayer
                                    data={{
                                      posterSrc,
                                      videoAsset,
                                    }}
                                    rewindOnPause={true}
                                    subcopy={subcopy}
                                    tag={tag}
                                    tertiaryCopy={tertiaryCopy}
                                    title={title}
                                    urlRef={urlRef}
                                  />
                                </div>
                              );
                            }

                            console.error(
                              `Error: Unable to render this Contentful Entry in moduleEditorialGrid2Column: "${JSON.stringify(
                                mediaFields
                              )}. Please check media type." `
                            );
                          })}
                        </div>
                      </Tween>
                    );
                  }}
                </Scene>
              );
            })}
          </Controller>
        ) : (
          <Slider
            arrows={false}
            centerMode={false}
            centerPadding="10%"
            className="col"
            dots={false}
            infinite={false}
            slidesToScroll={1}
            slidesToShow={isMobile ? 1.25 : 2.25}
            speed={500}
          >
            {cards.map((card) => {
              const {
                sys: { id },
                fields: {
                  media: { fields: mediaFields },
                  title,
                  subtitle,
                  subcopy,
                  tag,
                  tertiaryCopy,
                  urlRef,
                  cta,
                  eyebrow,
                },
              } = card;

              if (mediaFields.imageTitle) {
                return (
                  <EditorialCard2Column
                    colIndex={0}
                    cta={cta}
                    eyebrow={eyebrow}
                    id={id}
                    key={id}
                    mediaFields={mediaFields}
                    subcopy={subcopy}
                    subtitle={subtitle}
                    tag={tag}
                    tertiaryCopy={tertiaryCopy}
                    title={title}
                    urlRef={urlRef}
                  />
                );
              } else if (mediaFields.videoTitle) {
                const videoAsset = mediaFields.videoFile
                  ? mediaFields.videoFile.fields.file
                  : null;
                const posterSrc = mediaFields.coverImageFile
                  ? `https:${mediaFields.coverImageFile.fields.file.url}`
                  : null;

                return (
                  <div className={styles.editorialSingleColumnCard} key={id}>
                    <VideoPlayer
                      data={{
                        posterSrc,
                        videoAsset,
                      }}
                      rewindOnPause={true}
                      subcopy={subcopy}
                      tag={tag}
                      tertiaryCopy={tertiaryCopy}
                      title={title}
                      urlRef={urlRef}
                    />
                  </div>
                );
              }

              console.error(
                `Error: Unable to render this Contentful Entry in moduleEditorialGrid2Column: "${JSON.stringify(
                  mediaFields
                )}. Please check media type." `
              );
            })}
          </Slider>
        )}
      </div>
    </section>
  );
};

EditorialGrid2ColumnModule.propTypes = {
  data: PropTypes.shape({
    anchorTag: PropTypes.string,
    cards: PropTypes.arrayOf(PropTypes.object).isRequired,
    editorialTitle: PropTypes.object.isRequired,
    marginBottom: PropTypes.string,
    subNavLabel: PropTypes.string,
    subNavText: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
  isDesktop: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  propStyles: PropTypes.string,
};

export default withScreenDimensions(EditorialGrid2ColumnModule);
